<template>
  <div>
    <div class="headers">
      <img src="../assets/logo.png" alt="" class="logo">
    </div>
    <van-form>
      <p class="title">订单信息</p>
      <van-field
          v-model="form.orderId"
          name="name"
          label="订单编号"
          readonly

      />
      <van-field
          v-model="form.giftName"
          name="name"
          label="赠品名称"
          readonly
      />
      <van-field
          v-model="form.giftCount"
          label="赠品数量"
          readonly
      />
      <p class="title">收货信息</p>
      <van-field
          v-model="form.deliveryName"
          label="收货人姓名"
          required
          placeholder="请填写收货人姓名"
          :rules="[{ required: true, message: '请填写用户姓名' }]"
          :readonly="!needPly"
      />
      <van-field
          v-model="form.deliveryTel"
          required
          label="收货人手机号"
          placeholder="请输入收货人手机号"
          :readonly="!needPly"
      />
      <van-field
          v-model="form.userReceiveAddress"
          required
          label="城市"
          readonly
      />
      <van-field
          clickable
          required
          :rules="[{ required: true, message: '请选择地区' }]"
          :value="form.countyName"
          label="区/县"
          placeholder="点击选择地区"
          @click="showPicker = true"
          v-if="needPly"
      />
      <van-field
          required
          :value="form.countyName"
          label="区/县"
          v-if="!needPly"
          readonly
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
          v-model="form.address"
          label="地址"
          required
          placeholder="请填写详细地址"
          :readonly="!needPly"
          :rules="[{ required: true, message: '请填写详细地址' }]"
      />
      <van-field
          v-model="form.customerTel"
          required
          label="客户手机号"
          type="tel"
          placeholder="请输入手机号"
          readonly
      />
      <van-field
          v-model="form.smsCode"
          center
          clearable
          required
          label="短信验证码"
          type="tel"
          placeholder="请输入短信验证码"
          v-if="needPly"
          :rules="[{ required: true, message: '请输入短信验证码' }]"
      >
        <template #button>
          <span v-show="sendAuthCode" class="auth_text auth_text_blue"
                @click="getAuthCode(form.customerTel)">获取验证码</span>
          <span v-show="!sendAuthCode" class="auth_text"> <span
              class="auth_text_blue">{{ auth_time }} </span> 秒可重新发送验证码</span>
        </template>
      </van-field>
      <p class="title">客户须知</p>
      <div class="instructions">
        <p v-for="item in instructions">{{item}}</p>
      </div>
      <div style="padding: 15px 0">
        <van-button round block class="button-sub" @click="onSubmit()" v-if="needPly">提交</van-button>
        <van-button round block class="button-already" v-if="!needPly">已提交</van-button>
      </div>

    </van-form>
  </div>
</template>

<script>
import {
  addOrderAddress,
  getReceiveMessage,
  getByCode,
  getAreasByPartent
} from '@/request/api'
import {Notify} from 'vant';

export default {
  data() {
    return {
      msgTip: '获取验证码',
      isBtnDisabled: true,
      sendAuthCode: true,
      auth_time: 0,
      time: 60,
      columns: [],
      showPicker: false,
      needPly: true,
      form: {
        orderId: '',
        giftName: '',
        giftCount: '1',
        deliveryName:'',
        deliveryTel: '',
        userReceiveAddress: '',
        address:'',
        code: '',
        countyId: '',
        countyName:'',
        smsCode: '',
        customerTel:''
      },
      order: {},
      instructions:['1、收货准备：\n' ,
      '电视收货请务必本人签收，在签收前进行开箱验机，签收后12小时内进行通电验机。物流师傅上门前会电话联系，如有任何不便，请根据情况沟通合适的上门时间。\n' ,
      '\n' ,
      '2、关于物流及上楼费说明：\n' ,
      '①电梯房用户及非电梯房1楼用户免收取上楼费用；\n' ,
      '②非电梯房2楼及以上用户如有上楼需求，需收取相应上楼费用，具体收费标准以京东合作物流告知为准；部分偏远乡镇农村区域无法进行派送，具体派送范围以京东合作物流查询为准。\n' ,
      '\n' ,
      '3、检验签收&验机步骤：\n' ,
      '①完全取出电视。\n' ,
      '②检查电视外观完好，屏幕是否完好，有无划痕。（如发现质量问题请务必拒绝签收，并联系家FUN客服进行报备）\n' ,
      '③签收12小时内务必对电视进行通电验机（如发现质量问题请立即联系家FUN客服报备，以维护您的权益；不开箱验机或未及时通电验机的，后续发现屏幕问题本司不予负责）\n' ,
      '\n' ,
      '4、关于安装\n' ,
      '①电视产品为特殊易碎品，建议您联系电视品牌专业的售后上门进行安装服务。如因自行安装造成电视机损坏，本司不予负责；\n' ,
      '②电视安装产生的各项费用由客户自行承担，具体收费标准以电视品牌官方收费标准为准。\n' ,
      '\n' ,
      '5、关于电视保修\n' ,
      '按照国家三包规定享受服务（整机保修一年；主要部件显示屏、背光组件、逻辑组件、高频调谐器保修三年）。保修期内提供免费上门服务。\n','']

    };
  },
  created() {
    // this.$route.query.code
    this.getByOrderId()
  },
  methods: {
    //通过订单编号获取赠品信息
    getByOrderId() {
      this.form.code = this.$route.query.code
      getByCode({str: this.form.code}).then(res => {
        let order = {}
        if (res.data.success) {
          order = res.data.data
          this.form.orderId =  order.orderId
          this.form.giftName =  order.giftName
          this.form.deliveryName =  order.deliveryName
          this.form.deliveryTel =  order.deliveryTel
          this.form.address =  order.address
          this.form.customerTel =  order.customerTel
          this.form.countyName =  order.countyName
          this.form.userReceiveAddress =  order.provinceName + '/' + order.cityName + '(不可修改)'
          if (order.state == '2' || order.state == '3') {
            this.needPly = false
          }
          this.getBycity(order.cityId)
        }else {
          Notify({ type: 'danger', message: res.data.message });
        }
      })
    },
    getBycity(id){
      getAreasByPartent({id: id}).then(res => {
        let area = []
        if (res.data.success) {
          this.columns = res.data.data
            res.data.data.forEach(item => {
               area.push({
                 text: item.areaName,
                 id: item.idtAreas
               })
            })
            this.columns = area
        }else {
          Notify({ type: 'danger', message: res.data.message });
        }
      })
    },
    //选择区
    onConfirm(value) {
      this.form.countyId = value.id
      this.form.countyName = value.text
      this.showPicker = false;
    },
    //发送验证码
    getAuthCode() {
      this.sendAuthCode = false;
      this.auth_time = 30;
      getReceiveMessage({
        mobile: this.form.customerTel
      }).then(res => {
        if (res.data.success) {
          Notify({type: 'success', message: '发送成功'});
        }else {
          Notify({ type: 'danger', message: res.data.message });
        }
      })
      let auth_timetimer = setInterval(() => {
        this.auth_time--;
        if (this.auth_time <= 0) {
          this.sendAuthCode = true;
          clearInterval(auth_timetimer);
        }
      }, 1000);

    },
    onSubmit() {
      if(this.form.countyName == ''){
        Notify({ type: 'danger', message: '请选择相关区域' });
        return;
      }
      if (this.form.deliveryName == '' || this.form.deliveryTel == '' || this.form.customerTel == '' || this.form.smsCode == '') {
        return
      } else {
        addOrderAddress(this.form).then(res => {
          if (res.data.success) {
            Notify({type: 'success', message: '提交成功'});
            this.needPly = false
          }else {
            Notify({ type: 'danger', message: res.data.message });
          }
        })
      }
    }
  },
};
</script>

<style scoped>
.title {
  margin: 0;
  padding: 16px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.button-sub {
  background-color: rgba(178, 132, 72, 1);
  color: #fff;
}

.button-already {
  background-color: rgb(223, 201, 171);
  color: #fff;
}

.instructions {
  background: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  color: #9c9c9c;
  font-size: 14px;
  line-height: 1.5;
}
 .headers {
   background: #fff;
   padding: 8px 0 5px 0;
 }

.logo {
  width: 35%;
}
</style>